/* eslint-disable no-unused-vars */

import Creative from "../Creative";
import groups from "./groups";
import {
  combo,
  stepWithFallback,
  setCreativesGroup,
  setCreativesHiddenAfterAt,
  setCreativesVisibleAfterAt,
  whenSplit,
  whenSplitInRange,
  getCreativesInRandomOrder,
  skipOnMultifaceError,
  getCreativesInRandomOrderWithWeights
} from "./helpers";
import CreativeConfig from "../CreativeConfig";
import {handlersNames} from "../handlers";

function getGroups() {
  return [
    groups.g1,
  ];
}

const types = {
  newFace: {templateId: 8658, canvasId: 8659, maskId: 8660},
  newSkin: {templateId: 8658, canvasId: 8661, maskId: 8662},
  newHead: {templateId: 8658, canvasId: 8663, maskId: 8664},
};

const faces = {
  celeb: 7088,
  fiver: 8682,
  prequel: 6683,
};

const list = [
  {
    ...types.newFace,
    faceId: faces.celeb,
    seed: 45621,
    prompt: "inside cyberpunk extremely blockchain city, extra detailed city on background, chains connecting blocks, blockchain, symmetry, intricate, volumetric lighting, beautiful, sharp focus, ultra detailed, in the style of dan mumford and marc simonetti, voxel city bitcoin, violet grading, cyan, cold grading, red, violet, blue colored detailed voxel background, pink detailed, hyperdetailed, hyperrealistic, trending on artstation, featured on artstation, masterpiece, blockchain logo, bitcoin chain on neck, bitcoin logo",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, blurred background",
  },
  {
    ...types.newFace,
    faceId: faces.celeb,
    seed: 54670,
    prompt: "inside cyberpunk extremely blockchain city, extra detailed city on background, chains connecting blocks, blockchain, symmetry, intricate, volumetric lighting, beautiful, sharp focus, ultra detailed, in the style of dan mumford and marc simonetti, voxel city bitcoin, violet grading, cyan, cold grading, red, violet, blue colored detailed voxel background, pink detailed, hyperdetailed, hyperrealistic, trending on artstation, featured on artstation, masterpiece, blockchain logo, bitcoin chain on neck, bitcoin logo",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, blurred background",
  },
  {
    ...types.newFace,
    preFaceId: 8684,
    faceId: faces.celeb,
    seed: 72886,
    prompt: "show metaverse planets galaxy background, neon outline, neon glow colour, metaverse illustration, concept art,  high quality, highly detailed, elegant, sharp focus, digital painting, shine, artstation",
    negativePrompt: "earrings,(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo",
  },
  {
    ...types.newFace,
    preFaceId: 8684,
    faceId: faces.celeb,
    seed: 27443,
    prompt: "show metaverse planets galaxy background, neon outline, neon glow colour, metaverse illustration, concept art,  high quality, highly detailed, elegant, sharp focus, digital painting, shine, artstation",
    negativePrompt: "earrings,(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo",
  },
  {
    ...types.newFace,
    preFaceId: 8688,
    faceId: faces.celeb,
    seed: 32155,
    prompt: "cityscape with huge piles of crypto coins, piles of coins, concept art, award winning concept art, trending on artstation, featured on artstation, masterpiece",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo",
  },
  {
    ...types.newFace,
    preFaceId: 8688,
    faceId: faces.celeb,
    seed: 36910,
    prompt: "cityscape with huge piles of crypto coins, stacks of coins in the foreground at the bottom, concept art, award winning concept art, trending on artstation, featured on artstation, masterpiece",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo",
  },
  {
    ...types.newSkin,
    preFaceId: 8687,
    faceId: faces.fiver,
    seed: 35045,
    prompt: "Flat design, portrait of a person from abstract lines, Network scientist, Metaverse, Blockchain, radiating a yellow glowing aura stuff, stylized, digital illustration, Crypto and Token icon, minimalism, rossdraws coding aesthetic, trending on artstation, featured on artstation, masterpiece",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, headphones, hood",
  },
  {
    ...types.newSkin,
    preFaceId: 8687,
    faceId: faces.fiver,
    seed: 36310,
    prompt: "Flat design, portrait of a person from abstract lines, Network scientist, Metaverse, Blockchain, radiating a yellow glowing aura stuff, stylized, digital illustration, Crypto and Token icon, minimalism, rossdraws coding aesthetic, trending on artstation, featured on artstation, masterpiece",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, headphones, hood",
  },
  {
    ...types.newSkin,
    preFaceId: 8685,
    faceId: faces.celeb,
    seed: 4742,
    prompt: "A person programming, Blockchain, NFT, Crypto, Token, Network, fantasy, future, pastel colors aesthetic, intricate fashion clothing, highly detailed, surrealistic, digital painting, concept art, sharp focus, illustration, coding aesthetic, trending on artstation, featured on artstation, masterpiece",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, headphones, hood",
  },
  {
    ...types.newSkin,
    preFaceId: 8685,
    faceId: faces.celeb,
    seed: 40524,
    prompt: "A person programming, Blockchain, NFT, Crypto, Token, Network, fantasy, future, pastel colors aesthetic, intricate fashion clothing, highly detailed, surrealistic, digital painting, concept art, sharp focus, illustration, coding aesthetic, trending on artstation, featured on artstation, masterpiece",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, headphones, hood",
  },
  {
    ...types.newSkin,
    preFaceId: 8689,
    faceId: faces.celeb,
    seed: 11785,
    prompt: "bitcoin rain, futuristic, ultra detailed, photorealistic, blue and pink colours",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo",
  },
  {
    ...types.newSkin,
    preFaceId: 8689,
    faceId: faces.celeb,
    seed: 93467,
    prompt: "bitcoin rain, futuristic, ultra detailed, photorealistic, blue and pink colours",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo",
  },
  {
    ...types.newSkin,
    faceId: faces.celeb,
    seed: 45046,
    prompt: "Detailed portrait neon operator, cyberpunk futuristic neon, reflective puffy coat, decorated with traditional japanese ornaments by ismail inceoglu dragan bibin hans thoma greg rutkowski alexandros pyromallis nekro rene maritte illustrated, perfect face, fine details, realistic shaded, fine-face, pretty face, bitcoin logo, bitcoin, cryptocyrrency holder",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, naked body, earings",
  },
  {
    ...types.newSkin,
    faceId: faces.celeb,
    seed: 19948,
    prompt: "Detailed portrait neon operator, cyberpunk futuristic neon, reflective puffy coat, decorated with traditional japanese ornaments by ismail inceoglu dragan bibin hans thoma greg rutkowski alexandros pyromallis nekro rene maritte illustrated, perfect face, fine details, realistic shaded, fine-face, pretty face, bitcoin logo, bitcoin, cryptocyrrency holder",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, naked body, earings",
  },
  {
    ...types.newSkin,
    faceId: faces.celeb,
    seed: 11180,
    prompt: "blockchain anime, cubes connected with lines, bitcoin, futuristic masked cyberpunk edgerunner, particles effects, volumetric light, heavy abstract cryptic rain, neon lights, walking in a cyberpunk city, enormous bitcoins, flying neon cubes, masterpiece, trending on artstation, featured on artstation, Vintage 90's anime style. cluttered 7/11 interior; a woman wearing streetwear; by Fujiko, sci-fi, colors, neon lights. line art.",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, naked skin, nude, naked body",
  },
  {
    ...types.newSkin,
    faceId: faces.celeb,
    seed: 19767,
    prompt: "blockchain anime, cubes connected with lines, bitcoin, futuristic masked cyberpunk edgerunner, particles effects, volumetric light, heavy abstract cryptic rain, neon lights, walking in a cyberpunk city, enormous bitcoins, flying neon cubes, masterpiece, trending on artstation, featured on artstation, Vintage 90's anime style. cluttered 7/11 interior; a woman wearing streetwear; by Fujiko, sci-fi, colors, neon lights. line art.",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, naked skin, nude, naked body",
  },
  {
    ...types.newFace,
    preFaceId: 8686,
    faceId: faces.celeb,
    seed: 23058,
    prompt: "cyberpunk, sitting on a computer desk, in the background is a hologram of cryptocurrency quotes, on the sides there are TVs with code, gold coins are flying around,  trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by greg rutkowski, neon, neural network, deep learning,  intricate organic, tom bagshaw, ruan jia, ambigrammatic, vivid portrayal, engrossing deta, symbol-patterned background, veritrelex, fractal album cover, symbolic, blockchain",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, headphones",
  },
  {
    ...types.newFace,
    preFaceId: 8686,
    faceId: faces.celeb,
    seed: 63025,
    prompt: "cyberpunk, sitting on a computer desk, in the background is a hologram of cryptocurrency quotes, on the sides there are TVs with code, gold coins are flying around,  trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by greg rutkowski, neon, neural network, deep learning,  intricate organic, tom bagshaw, ruan jia, ambigrammatic, vivid portrayal, engrossing deta, symbol-patterned background, veritrelex, fractal album cover, symbolic, blockchain",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, headphones",
  },
  {
    ...types.newFace,
    preFaceId: 8684,
    faceId: faces.prequel,
    seed: 65299,
    prompt: "Сyborg hacker portrait, looks like rich human, futuristic city on background, cryptocurrency symbols bokeh, 8k, style of eduardo kobra, digital painting illustration, vibrant colors,  trending on artstation, featured on artstation, masterpiece",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo",
  },
  {
    ...types.newFace,
    preFaceId: 8684,
    faceId: faces.prequel,
    seed: 50261,
    prompt: "Сyborg hacker portrait, looks like rich human, futuristic city on background, cryptocurrency symbols bokeh, 8k, style of eduardo kobra, digital painting illustration, vibrant colors,  trending on artstation, featured on artstation, masterpiece",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo",
  },
  {
    ...types.newFace,
    preFaceId: 8685,
    faceId: faces.celeb,
    seed: 19926,
    prompt: "A person, Blockchain, Crypto, Token, Metaverse, Network scientist, computers and holograms, hacking the metaverse, hyperrealistic oil painting, by makoto shinkai, ilya kuvshinov, lois van baarle, rossdraws, basquiat, in the style of hearthstone, dark color scheme, trending on artstation, featured on artstation, masterpiece",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, headphones",
  },
  {
    ...types.newFace,
    preFaceId: 8685,
    faceId: faces.celeb,
    seed: 10030,
    prompt: "A person, Blockchain, Crypto, Token, Metaverse, Network scientist, computers and holograms, hacking the metaverse, hyperrealistic oil painting, by makoto shinkai, ilya kuvshinov, lois van baarle, rossdraws, basquiat, in the style of hearthstone, dark color scheme, trending on artstation, featured on artstation, masterpiece",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, headphones",
  },
  {
    ...types.newSkin,
    preFaceId: 8688,
    faceId: faces.prequel,
    seed: 5548,
    prompt: "A person, whirlwind inside the metaverse, Blockchain, Token, Network hologram, neurochip, android, by loish, elegant, strong, highly detailed, colorful, digital painting, concept art, art by artgerm and greg rutkowski and alphonse mucha, trending on artstation, featured on artstation, masterpiece",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, headphones, hood",
  },
  {
    ...types.newSkin,
    preFaceId: 8688,
    faceId: faces.prequel,
    seed: 83607,
    prompt: "A person, whirlwind inside the metaverse, Blockchain, Token, Network hologram, neurochip, android, by loish, elegant, strong, highly detailed, colorful, digital painting, concept art, art by artgerm and greg rutkowski and alphonse mucha, trending on artstation, featured on artstation, masterpiece",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, headphones, hood",
  },
  {
    ...types.newSkin,
    preFaceId: 8690,
    faceId: faces.celeb,
    seed: 83570,
    prompt: "A person, Network scientist, inside cosmic labratory, Metaverse, Blockchain, Token, radiating a yellow glowing aura stuff, stylized, digital illustration, Crypto icon, minimalism, ilya kuvshinov, rossdraws coding aesthetic, trending on artstation, featured on artstation, masterpiece",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, headphones, hood",
  },
  {
    ...types.newSkin,
    preFaceId: 8690,
    faceId: faces.celeb,
    seed: 85676,
    prompt: "A person, Network scientist, inside cosmic labratory, Metaverse, Blockchain, Token, radiating a yellow glowing aura stuff, stylized, digital illustration, Crypto icon, minimalism, ilya kuvshinov, rossdraws coding aesthetic, trending on artstation, featured on artstation, masterpiece",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, headphones, hood",
  },
  {
    ...types.newFace,
    preFaceId: 8690,
    faceId: faces.prequel,
    seed: 42428,
    prompt: "A person, Network scientist, inside cosmic labratory, Metaverse, Blockchain, Token, radiating a yellow glowing aura stuff, stylized, digital illustration, Crypto icon, minimalism, ilya kuvshinov, rossdraws coding aesthetic, trending on artstation, featured on artstation, masterpiece",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, headphones, hood",
  },
  {
    ...types.newFace,
    preFaceId: 8690,
    faceId: faces.prequel,
    seed: 13967,
    prompt: "A person, Network scientist, inside cosmic labratory, Metaverse, Blockchain, Token, radiating a yellow glowing aura stuff, stylized, digital illustration, Crypto icon, minimalism, ilya kuvshinov, rossdraws coding aesthetic, trending on artstation, featured on artstation, masterpiece",
    negativePrompt: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, headphones, hood",
  },
];

function getCreatives(fileIndex) {
  const creatives = [];

  list.forEach((item) => {
    const steps = [
      {id: "gender_classifier"},
    ];

    if (item.preFaceId) {
      steps.push({id: item.preFaceId, images: [{src: "@" + fileIndex}]});
      steps.push({id: item.faceId});
    } else {
      steps.push({id: item.faceId, images: [{src: "@" + fileIndex}]});
    }

    steps.push({
      id: item.templateId,
      templateParams: {
        canvas_template_name: item.canvasId,
        mask_template_name: item.maskId,
        gender: "#0",
        seeds: item.seed,
        prompt: item.prompt,
        negative_prompt: item.negativePrompt,
      },
    });

    steps.push({id: 8691});

    creatives.push(combo(steps));
  });

  return setCreativesGroup(groups.g1, creatives);
}

export default {
  getGroups: function() {
    return getGroups();
  },
  getCreatives: function(fileIndex) {
    return getCreatives(fileIndex);
  },
};
