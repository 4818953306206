import React from 'react';
import AppContext from "../../contexts/AppContext";
import {mapSelectedImageItem, webviewAnalyticsEvent, webviewCall} from "../../utils/webview";
import clientStorage from "../../utils/client-storage";
import uploadHandler from "../../utils/upload.handler";
import routes from "../../routes";
import i18n from "../../i18n";
import {debounce} from "../../utils/etc";
import ImageErrorModal from "./ImageErrorModal";
import {imageStatus} from "./shared";
import {Alert, Button, Col, Container, Figure, Row} from "react-bootstrap";
import * as api from "../../utils/api";

export default class PhotochooserPage extends React.Component {

  state = {
    images: clientStorage.getLatestSelectedImages(),
  };

  fileFieldRef = React.createRef();
  imageTasks = [];
  imagesNumber = this.state.images.isNotEmpty()
    ? this.state.images.max((i) => i.number)
    : 0;

  componentDidMount() {
    this.context.showLoader();

    api.getBuildInfo()
      .then((result) => {
        if (parseInt(window.appConfig.build.version) < parseInt(result.version)) {
          setTimeout(() => {
            window.location.replace("/?r=" + Math.random())
          }, 1000);
        } else {
          this.context.hideLoader();
        }
      })
      .catch((err) => {
        window.location.replace("/?r=" + Math.random());
      });

    window.webviewEventsListeners.photoSelected.setListener(this.handleWebviewFileSelected);
    window.webviewEventsListeners.backPress.push(() => {
      this.props.history.replace(routes.INDEX);
      return true;
    });

    this.checkPendingImages();
  }

  componentWillUnmount() {
    window.webviewEventsListeners.photoSelected.removeListener();
    window.webviewEventsListeners.backPress.pop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    clientStorage.setLatestSelectedImages(this.state.images.map((image) => {
      return Object.assign({}, image, {checked: false});
    }));

    this.checkPendingImages();
  }

  checkPendingImages = () => {
    debounce("PhotochooserPage_checkPendingImages", 200, () => {
      this.state.images
        .filter((image) => image.status === imageStatus.pending)
        .forEach((image, index) => this.checkImage(image, index));
    });
  };

  requestPhotoChooser = () => {
    webviewCall("nativePhotoSelect", {
      func: "onNativeAppPhotoSelected",
      use_crop: 0,
      num_photos: 10,
      show: "gallery",
      tab: "faces",
      neurocamera: 1,
    });
  };

  checkImage = (image) => {
    if (this.imageTasks[image.url]) {
      return;
    }

    image.status = imageStatus.valid;
    this.setState({images: this.state.images.slice()});

    this.imageTasks[image.url] = Promise.resolve(image);
  };

  handleWebviewFileSelected = (data) => {
    if (!data || !data.photos || data.photos.length === 0) {
      return;
    }

    const stateImages = this.state.images.slice();

    data.photos
      .map((image) => mapSelectedImageItem(image))
      .forEach((image) => {
        if (stateImages.findIndex((_) => _.url === image.url) === -1) {
          image.status = imageStatus.pending;
          image.number = ++this.imagesNumber;
          stateImages.push(image);

          webviewAnalyticsEvent("photo_selected", [
            "",
            "photo_chooser",
            clientStorage.getSelectedPhotosAmount() + 1,
            image.number,
          ]);
        }
      });

    this.setState({
      images: stateImages.filter((i) => i.status !== imageStatus.invalid)
    });
  };

  handleBrowserFileSelected(images) {
    this.context.showLoader();

    const stateImages = this.state.images.slice();

    Promise.all([...images].map((image) => uploadHandler(image).catch(() => {/* ignore */})))
      .then((images) => {
        images
          .filter(Boolean)
          .forEach((image) => {
            image.status = imageStatus.pending;
            image.number = ++this.imagesNumber;
            stateImages.push(image);
          });

        this.setState({
          images: stateImages.filter((i) => i.status !== imageStatus.invalid)
        }, this.context.hideLoader);
      })
      .catch(console.error);
  }

  handleRemoveImage = (image) => {
    const pos = this.state.images.findIndex((_) => _.url === image.url);

    if (pos > -1) {
      this.state.images.splice(pos, 1);
      this.setState({
        images: this.state.images.slice(),
      });
    }
  };

  handleImageClick = (image) => {
    if (image.status === imageStatus.invalid) {
      this.context.pushModal(<ImageErrorModal
        key="CreatePage_ImageErrorModal"
        image={image}
        hideCloseButton={true}
        onOkClick={() => this.handleRemoveImage(image)}
      />);
      return;
    }

    this.handleRemoveImage(image);
  };

  handleProcessClick = () => {
    this.handleProcess();
  };

  handleProcess = () => {
    const images = this.state.images
      .filter((image) => image.status === imageStatus.valid);

    if (images.isEmpty()) {
      return;
    }

    this.props.history.push(routes.UPLOAD, {files: images})
  };

  handleAddPhotosClick = () => {
    if (window.clientConfig.isWeb) {
      if (this.fileFieldRef) {
        this.fileFieldRef.value = "";
        this.fileFieldRef.click();
      }
    } else {
      this.requestPhotoChooser();
    }
  };

  render() {
    const validImagesCount = this.state.images.count((image) => {
      return image.status === imageStatus.valid;
    });

    return <React.Fragment>
      <Container fluid="md">

        <p dangerouslySetInnerHTML={{__html: i18n.t("photochooser_photos_count", {num: validImagesCount})}}/>

        <Button onClick={this.handleAddPhotosClick} size="lg">
          {i18n.t("button__select_photos")}
        </Button>
        &nbsp;
        <Button onClick={this.handleProcessClick} hidden={validImagesCount < 1} variant="success" size="lg">
          {i18n.t("button__proceed")}
        </Button>

        <hr />
        <Alert variant="secondary">
          {i18n.t("button__tap_to_delete")}
        </Alert>

        <Row>
          {this.state.images.map((image) => <Col key={image.url} xs={6} md={3} lg={2}>
            <Figure onClick={() => this.handleImageClick(image)}>
              <Figure.Image src={image.url} />
            </Figure>
          </Col>)}
        </Row>


        <Alert variant="secondary">
          На странице результатов в режиме просмотра фото<br />
          - стрелки влево и вправо листают результаты<br />
          - Esc закрывает фото<br />
          - d скачивает фото
        </Alert>
      </Container>

      <input
        className="file-field-hidden"
        type="file"
        accept="image/*"
        multiple
        ref={(ref) => this.fileFieldRef = ref}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => this.handleBrowserFileSelected(e.target.files)} />
    </React.Fragment>;
  }
}

PhotochooserPage.contextType = AppContext;
